table {
  width: 100%;
}

table tbody {
}

table th,
table td {
  @extend .lato400;
  font-size: rem(16);
  line-height: line-height(16, 24);
  color: $color-text;
  text-align: left;
  padding: 20px 0 20px 35px;
  border-bottom: 1px solid #d1d1d1;
}

table td:first-child {
  @extend .lato700;
  padding: 20px 35px 20px 0;
  border-right: 1px solid #d9d9d9;
  // white-space: nowrap;
}

table tr:last-child td {
  border-bottom: none;
}

//.table-orders{
//
//  @include media-breakpoint-down(800px) {
//    th:nth-child(4n),td:nth-child(4n){
//      display: none;
//    }
//  }
//
//  @include media-breakpoint-down(700px) {
//    th:nth-child(3n),td:nth-child(3n){
//      display: none;
//    }
//  }
//
//  @include media-breakpoint-down(sm) {
//    th, td{
//      padding: 15px 5px;
//    }
//  }
//
//  @include media-breakpoint-down(xs) {
//    th{display: none}
//    tr{
//      display: block;
//      padding: 10px 0;
//      border-bottom: 1px solid #d1d1d1;
//    }
//    td{
//      display: block;
//      padding: 10px 20px;
//      border: 0;
//    }
//  }
//}
//
//.table-products{
//
//  th, td{
//    border: 0;
//  }
//
//  th:first-child, td:first-child{
//    min-width: 400px;
//  }
//
//  tbody{
//    border-top: 1px solid #d1d1d1;
//    border-bottom: 1px solid #d1d1d1;
//  }
//
//  .details{
//    display: flex;
//    align-items: center;
//  }
//
//  .details-img{
//    max-width: 150px;
//    width: 100%;
//    border: 1px solid #d1d1d1;
//    margin-right: 30px;
//  }
//
//  label{
//    padding-right: 15px;
//  }
//
//  .price{
//    @extend .lato400;
//    font-size: rem(24);
//    line-height: 1;
//    color: #646464;
//    white-space: nowrap;
//    margin: 8px 0;
//  }
//
//  .icon-delete{
//    width: 24px;
//    height: 24px;
//    fill: #e21b22;
//    transform: translateX(12px);
//  }
//
//  @include media-breakpoint-down(lg) {
//
//    .details-img{max-width: 125px}
//
//    h3{font-size: rem(20)}
//
//    .price{font-size: rem(20)}
//
//    .quantity:not(.small){
//      .form-control{
//        width: 45px;
//        height: 45px;
//      }
//      .minus, .plus{font-size: 1.6rem}
//    }
//  }
//
//  @include media-breakpoint-down(md) {
//
//    th:first-child, td:first-child{
//      display: block;
//      min-width: unset;
//      padding-right: 0;
//    }
//
//    th:not(:first-child), td:not(:first-child){
//      display: none;
//    }
//
//    .details-img{
//      max-width: 150px;
//      margin-right: 15px;
//    }
//
//    .details-desc{
//      flex: 0 0 auto;
//      max-width: 100%;
//    }
//
//    h3{
//      font-size: rem(18);
//      margin-bottom: 10px;
//    }
//
//    .price{font-size: rem(18);}
//
//    .icon-delete{transform: none}
//  }
//
//  @include media-breakpoint-down(sm) {
//    h3{font-size: rem(16);}
//    .price{font-size: rem(16);}
//  }
//}
