.hide-xs {
  @include media-breakpoint-down(xs) {
    display: none!important;
  }
}

.show-xs {
  @include media-breakpoint-up(xs) {
    display: none!important;
  }
}

.hide-sm {
  @include media-breakpoint-down(sm) {
    display: none!important;
  }
}


.show-sm {
  @include media-breakpoint-up(sm) {
    display: none!important;
  }
}


.hide-md {
  @include media-breakpoint-down(md) {
    display: none!important;
  }
}

.show-md {
  @include media-breakpoint-up(md) {
    display: none!important;
  }
}

.hide-lg {
  @include media-breakpoint-down(lg) {
    display: none!important;
  }
}

.show-lg {
  @include media-breakpoint-up(lg) {
    display: none!important;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter;
  margin-left: -$gutter;
}

.row>[class^="col"]{
  position: relative;
  width: 100%;
  padding-right: $gutter;
  padding-left: $gutter;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.h-100 {
  height: 100%!important;
}

.w-100 {
  width: 100%!important;
}

.col {
  flex: 1 1 0;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 (100% / 12);
  max-width: (100% / 12);
}

.col-2 {
  flex: 0 0 (100% / 6);
  max-width: (100% / 6);
}

.col-3 {
  flex: 0 0 (100% / 4);
  max-width: (100% / 4);
}

.col-4 {
  flex: 0 0 (100% / 3);
  max-width: (100% / 3);
}

.col-5 {
  flex: 0 0 (100% / (12 / 5));
  max-width: (100% / (12 / 5));
}

.col-6 {
  flex: 0 0 (100% / 2);
  max-width: (100% / 2);
}

.col-7 {
  flex: 0 0 (100% / (12 / 7));
  max-width: (100% / (12 / 7));
}

.col-8 {
  flex: 0 0 (100% / (12 / 8));
  max-width: (100% / (12 / 8));
}

.col-9 {
  flex: 0 0 (100% / (12 / 9));
  max-width: (100% / (12 / 9));
}

.col-10 {
  flex: 0 0 (100% / (12 / 10));
  max-width: (100% / (12 / 10));
}

.col-11 {
  flex: 0 0 (100% / (12 / 11));
  max-width: (100% / (12 / 11));
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-auto{
  flex: 0 0 auto;
  max-width: 100%;
  width: auto !important;
}

@media only screen and (min-width: 419.98px) {
  .col-xs-1 {
    flex: 0 0 (100% / 12);
    max-width: (100% / 12);
  }

  .col-xs-2 {
    flex: 0 0 (100% / 6);
    max-width: (100% / 6);
  }

  .col-xs-3 {
    flex: 0 0 (100% / 4);
    max-width: (100% / 4);
  }

  .col-xs-4 {
    flex: 0 0 (100% / 3);
    max-width: (100% / 3);
  }

  .col-xs-5 {
    flex: 0 0 (100% / (12 / 5));
    max-width: (100% / (12 / 5));
  }

  .col-xs-6 {
    flex: 0 0 (100% / 2);
    max-width: (100% / 2);
  }

  .col-xs-7 {
    flex: 0 0 (100% / (12 / 7));
    max-width: (100% / (12 / 7));
  }

  .col-xs-8 {
    flex: 0 0 (100% / (12 / 8));
    max-width: (100% / (12 / 8));
  }

  .col-xs-9 {
    flex: 0 0 (100% / (12 / 9));
    max-width: (100% / (12 / 9));
  }

  .col-xs-10 {
    flex: 0 0 (100% / (12 / 10));
    max-width: (100% / (12 / 10));
  }

  .col-xs-11 {
    flex: 0 0 (100% / (12 / 11));
    max-width: (100% / (12 / 11));
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xs-auto{
    flex: 0 0 auto;
    max-width: 100%;
    width: auto !important;
  }
}

@media only screen and (min-width: 575.98px) {
  .col-sm-1 {
    flex: 0 0 (100% / 12);
    max-width: (100% / 12);
  }

  .col-sm-2 {
    flex: 0 0 (100% / 6);
    max-width: (100% / 6);
  }

  .col-sm-3 {
    flex: 0 0 (100% / 4);
    max-width: (100% / 4);
  }

  .col-sm-4 {
    flex: 0 0 (100% / 3);
    max-width: (100% / 3);
  }

  .col-sm-5 {
    flex: 0 0 (100% / (12 / 5));
    max-width: (100% / (12 / 5));
  }

  .col-sm-6 {
    flex: 0 0 (100% / 2);
    max-width: (100% / 2);
  }

  .col-sm-7 {
    flex: 0 0 (100% / (12 / 7));
    max-width: (100% / (12 / 7));
  }

  .col-sm-8 {
    flex: 0 0 (100% / (12 / 8));
    max-width: (100% / (12 / 8));
  }

  .col-sm-9 {
    flex: 0 0 (100% / (12 / 9));
    max-width: (100% / (12 / 9));
  }

  .col-sm-10 {
    flex: 0 0 (100% / (12 / 10));
    max-width: (100% / (12 / 10));
  }

  .col-sm-11 {
    flex: 0 0 (100% / (12 / 11));
    max-width: (100% / (12 / 11));
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-auto{
    flex: 0 0 auto;
    max-width: 100%;
    width: auto !important;
  }
}

@media only screen and (min-width: 767.98px) {
  .col-md-1 {
    flex: 0 0 (100% / 12);
    max-width: (100% / 12);
  }

  .col-md-2 {
    flex: 0 0 (100% / 6);
    max-width: (100% / 6);
  }

  .col-md-3 {
    flex: 0 0 (100% / 4);
    max-width: (100% / 4);
  }

  .col-md-4 {
    flex: 0 0 (100% / 3);
    max-width: (100% / 3);
  }

  .col-md-5 {
    flex: 0 0 (100% / (12 / 5));
    max-width: (100% / (12 / 5));
  }

  .col-md-6 {
    flex: 0 0 (100% / 2);
    max-width: (100% / 2);
  }

  .col-md-7 {
    flex: 0 0 (100% / (12 / 7));
    max-width: (100% / (12 / 7));
  }

  .col-md-8 {
    flex: 0 0 (100% / (12 / 8));
    max-width: (100% / (12 / 8));
  }

  .col-md-9 {
    flex: 0 0 (100% / (12 / 9));
    max-width: (100% / (12 / 9));
  }

  .col-md-10 {
    flex: 0 0 (100% / (12 / 10));
    max-width: (100% / (12 / 10));
  }

  .col-md-11 {
    flex: 0 0 (100% / (12 / 11));
    max-width: (100% / (12 / 11));
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-md-auto{
    flex: 0 0 auto;
    max-width: 100%;
    width: auto !important;
  }
}

@media only screen and (min-width: 991.98px){
  .col-lg-1 {
    flex: 0 0 (100% / 12);
    max-width: (100% / 12);
  }

  .col-lg-2 {
    flex: 0 0 (100% / 6);
    max-width: (100% / 6);
  }

  .col-lg-3 {
    flex: 0 0 (100% / 4);
    max-width: (100% / 4);
  }

  .col-lg-4 {
    flex: 0 0 (100% / 3);
    max-width: (100% / 3);
  }

  .col-lg-5 {
    flex: 0 0 (100% / (12 / 5));
    max-width: (100% / (12 / 5));
  }

  .col-lg-6 {
    flex: 0 0 (100% / 2);
    max-width: (100% / 2);
  }

  .col-lg-7 {
    flex: 0 0 (100% / (12 / 7));
    max-width: (100% / (12 / 7));
  }

  .col-lg-8 {
    flex: 0 0 (100% / (12 / 8));
    max-width: (100% / (12 / 8));
  }

  .col-lg-9 {
    flex: 0 0 (100% / (12 / 9));
    max-width: (100% / (12 / 9));
  }

  .col-lg-10 {
    flex: 0 0 (100% / (12 / 10));
    max-width: (100% / (12 / 10));
  }

  .col-lg-11 {
    flex: 0 0 (100% / (12 / 11));
    max-width: (100% / (12 / 11));
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-lg-auto{
    flex: 0 0 auto;
    max-width: 100%;
    width: auto !important;
  }
}

@media only screen and (min-width: 1199.98px){
  .col-xl-1 {
    flex: 0 0 (100% / 12);
    max-width: (100% / 12);
  }

  .col-xl-2 {
    flex: 0 0 (100% / 6);
    max-width: (100% / 6);
  }

  .col-xl-3 {
    flex: 0 0 (100% / 4);
    max-width: (100% / 4);
  }

  .col-xl-4 {
    flex: 0 0 (100% / 3);
    max-width: (100% / 3);
  }

  .col-xl-5 {
    flex: 0 0 (100% / (12 / 5));
    max-width: (100% / (12 / 5));
  }

  .col-xl-6 {
    flex: 0 0 (100% / 2);
    max-width: (100% / 2);
  }

  .col-xl-7 {
    flex: 0 0 (100% / (12 / 7));
    max-width: (100% / (12 / 7));
  }

  .col-xl-8 {
    flex: 0 0 (100% / (12 / 8));
    max-width: (100% / (12 / 8));
  }

  .col-xl-9 {
    flex: 0 0 (100% / (12 / 9));
    max-width: (100% / (12 / 9));
  }

  .col-xl-10 {
    flex: 0 0 (100% / (12 / 10));
    max-width: (100% / (12 / 10));
  }

  .col-xl-11 {
    flex: 0 0 (100% / (12 / 11));
    max-width: (100% / (12 / 11));
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xl-auto{
    flex: 0 0 auto;
    max-width: 100%;
    width: auto !important;
  }
}

