$gutter: 15px;
$width: 100%;
//$breakpoint-extra-small: 460px;
//$breakpoint-medium: 600px;
//$breakpoint-medium: 960px;
//$breakpoint-large: 1280px;
//$breakpoint-extra-large: 1540px;

$color-primary: #1f284c;
$color-secondary: #61aabf;
$color-red: #d90000;
$color-text: #4d4d4d;

$z-index-dropdown: 1000 !default;
$z-index-sticky: 1020 !default;
$z-index-fixed: 1030 !default;
$z-index-modal-backdrop: 1040 !default;
$z-index-modal: 1050 !default;
$z-index-popover: 1060 !default;
$z-index-tooltip: 1070 !default;

$breakpoints: (
  xs: 420px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;
