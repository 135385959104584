// nav

.nav{
  display: flex;
  flex-wrap: wrap;
}

.nav-item{
  padding-right: 20px;
}

.nav-link{
  @extend .lato400;
  color: #646464;
  font-size: rem(15);
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  user-select: none;
  transition: color .15s ease-in-out, fill .15s ease-in-out;
}

.nav-item a.nav-link:hover, a.nav-link:hover, .nav-link:hover{color: #b7935d;}
.nav-item a.nav-link:hover [class^=icon-], a.nav-link:hover [class^=icon-], .nav-link:hover [class^=icon-]{fill: #b7935d;}

.link{
  @extend .lato400;
  font-size: rem(15);
  line-height: line-height(15,20);
  color: #00172b;
  text-decoration: underline;
  transition: color .15s ease-in-out;

  &:hover{
    color: #b7935d !important;
  }
}