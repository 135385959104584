.category-cards > div {
  margin-bottom: 30px;
}

.category-card {
  display: flex;
  align-items: center;
  height: 100%;
  background: #f2f3f4;
  padding: 15px 30px;
  border-radius: 5px;
  transition: box-shadow 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.category-card img {
  max-width: 40px;
  margin-right: 15px;
}

.category-card h3 {
  font-size: rem(16);
  transition: color 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

.category-card:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
