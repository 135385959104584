.footer {
  background-color: #f7f7f7;
  border-top: 2px solid #61aabf;
}

.footer-brand {
  display: block;
  width: 120px;
  height: auto;
  margin-bottom: 10px;
}

.footer-content {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.footer-content .footer-title {
  @extend .lato700;
  color: #1f284c;
  font-size: rem(20);
  line-height: line-height(16, 24);
  margin-bottom: 10px;
}

.footer-content ul {
  margin-bottom: 20px;

  li {
    margin-right: 15px;
    @extend .lato700;
    color: #1f284c;
    font-size: rem(20);
    line-height: line-height(16, 24);
    display: inline-block;
  }

  a {
    font-size: rem(14);
    white-space: nowrap;
  }
}

address {
  margin-top: 5px;
  @extend .lato700;
  color: $color-primary;
  font-size: rem(14);
  line-height: 2;

  span {
    color: $color-secondary;
  }
}

p.copyright {
  @extend .lato400;
  font-size: rem(12);
  color: #ffffff;
  margin-top: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer-left,
.footer-right {
  margin-top: 50px;
}

.footer-right {
  padding-left: 30px;
  border-left: 1px solid $color-primary;

  @include media-breakpoint-down(md) {
    padding-left: 0;
    border-left: 0;
  }
}

.footer-right > div {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
