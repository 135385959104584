// radio button

.radio-button {
  position: relative;
  padding-left: 30px;
  margin-right: 10px;
  min-height: 30px;
  line-height: 30px;
  width: auto;
  cursor: pointer;
  user-select: none;

  &:last-of-type{
    padding-bottom: 0;
  }
}

.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-button .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  border: 3px solid #d1d1d1;
  background-color: #ffffff;
  border-radius: 50%;
  transition: background-color .15s ease-in-out;
}

.radio-button .checkmark:after{
  content: "";
  position: absolute;
  display: none;
  top: -3px;
  left: -3px;
  width: 26px;
  height: 26px;
  border: 3px solid #61aabf;
  border-radius: 50%;
  background-color: transparent;
}

//.radio-button:hover input ~ .checkmark {
//  background-color: #b7935d;
//}
//
//.radio-button:hover input ~ .checkmark:after {
//  background-color: #ffffff;
//}

.radio-button input:checked ~ .checkmark {}

.radio-button input:checked ~ .checkmark:after {
  display: block;
}
