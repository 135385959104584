.cart-wrapper {
  position: relative;

  .cart-numb{
    position: absolute;
    top: -5px;
    right: -10px;
  }
}

.icon-cart{
  width: 28px;
  height: 28px;
}

.cart-numb {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-secondary;
  @extend .lato700;
  color: #ffffff;
  font-size: rem(12);
  line-height: 77px;
}
