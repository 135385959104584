.highlighted-cards > div {
  margin-bottom: 30px;
}

.highlighted-card {
  position: relative;
  display: flex;
  width: 100%;
  height: 400px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: transform 0.35s ease-in-out;
  }

  &:hover img {
    transform: scale(1.2);
  }
}

.highlighted-card-content {
  position: absolute;
  right: 40px;
  bottom: 30px;
  left: 20px;
  width: 75%;

  h3 {
    display: inline-block;
    // margin-bottom: 15px;
    // background: $color-secondary;
    margin-bottom: 5px;
    padding: 5px 10px;
    color: white;
  }

  .desc {
    span {
      // background: white;
      padding: 5px 10px;
      @extend .lato400;
      // color: $color-primary;
      color: white;
      font-size: rem(16);
      line-height: line-height(16, 32);
      -webkit-box-decoration-break: clone;
      -moz-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }
}
