.article-cards > div {
  margin-bottom: 30px;
}

.article-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;

  &:hover .article-body h3 {
    color: $color-secondary;
  }
}

.article-header {
  position: relative;
  height: 200px;

  @include media-breakpoint-down(sm) {
    height: 180px;
  }

  img {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    width: auto;
  }
}

.article-body {
  padding: 30px;

  @include media-breakpoint-down(xl) {
    padding: 15px;
  }

  h3 {
    font-size: rem(24);
    transition: color 0.15s ease-in-out;

    @include media-breakpoint-down(xl) {
      font-size: rem(24 * 0.85);
    }

    @include media-breakpoint-down(sm) {
      font-size: rem(24 * 0.75);
    }
  }

  .desc {
    @extend .lato400;
    color: #000000;
    font-size: rem(16);
    line-height: line-height(16, 24);
    padding: 30px 0;
    margin-top: auto;

    @include media-breakpoint-down(xl) {
      padding: 15px 0;
    }
  }

  .date {
    color: #b1b1b1;
  }
}
