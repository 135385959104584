// select

.select {
  position: relative;

  .form-control {
    cursor: pointer;
    padding-right: 42px;
  }

  .form-control:focus {
    background: #f2f3f4;
  }

  .icon-arrow {
    position: absolute;
    right: 14px;
    top: 14px;
    width: 14px;
    height: 14px;
    fill: #a5a6a8;
    cursor: pointer;
    transform: rotate(90deg);
  }
}

.select > .options {
  position: absolute;
  z-index: $z-index-dropdown;
  width: 100%;
  background: white;
  display: none;
  border: 1px solid #f2f3f4;
  border-top: none;
}

.options > .option {
  min-height: 40px;
  padding: 10px 20px;
  @extend .lato400;
  font-size: rem(15);
  line-height: 1;
  color: #646464;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  &:hover {
    color: $color-secondary;
    background-color: #f2f3f4;
  }

  &.active {
    color: $color-secondary;
  }
}

.select.open {
  .form-control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .icon-arrow {
    transform: rotate(270deg);
  }

  .options {
    display: block;
  }
}
