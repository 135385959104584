.product {
}

.product-summary {
  .form-group {
    margin-bottom: 0;
  }
}

.product-summary h1 {
}

.product-summary h4,
.product-summary .form-label {
  @extend .lato700;
  font-size: rem(16);
  margin-bottom: 20px;
}

.product-summary p {
  @extend .lato400;
  font-size: rem(15);
  line-height: line-height(15, 24);
  color: $color-text;
}

.product-summary .product-desc {
  margin-top: 15px;
  margin-bottom: 25px;
}

.product-summary .box {
  // padding-top: 35px;
}

.product-summary a {
  color: $color-secondary;
  &:hover {
    color: $color-primary;
  }
}

.feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  & > span {
    margin-right: 15px;
  }

  & > p {
    margin-top: 5px;
  }
}

.product-gallery {
  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}

.product-gallery {
  .fluid.image {
    cursor: default;
    display: block;
    height: 625px !important;
    background: white;

    @include media-breakpoint-down(md) {
      height: 400px !important;
    }

    @include media-breakpoint-down(xs) {
      height: 300px !important;
    }

    img {
      display: block;
      width: 100%;
      height: 100% !important;
      object-fit: contain;
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    margin: 5px -5px 20px;

    .image {
      width: calc(25% - 10px) !important;
      margin: 5px;
      height: 150px !important;
      background: white;

      @include media-breakpoint-down(sm) {
        height: 50px !important;
      }

      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.product-tag {
  display: inline-block;
  margin-bottom: 10px;
  background-color: $color-red;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  @extend .lato700;
  font-size: rem(16);
  line-height: 1;
  color: #ffffff;
  text-transform: uppercase;

  &.hidden {
    display: none;
  }
}

.product-price {
  margin-bottom: 25px;
  .prev-price {
    color: $color-primary;
    font-size: rem(14);
    margin-bottom: 5px;
  }
  .cur-price {
    color: $color-secondary;
    font-size: rem(20);
  }
}

.product-video iframe {
  width: 100%;
  height: 600px;

  @include media-breakpoint-down(lg) {
    height: 400px;
  }

  @include media-breakpoint-down(sm) {
    height: 300px;
  }
}
