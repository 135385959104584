.hero{
  position: relative;
  max-height: 580px;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    height: 420px !important;
  }
}

.hero:not(.hero-alt) .hero-image{

  @include media-breakpoint-down(lg) {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: initial;
    width: initial;
  }
}

.hero-content{
  position: absolute;
  bottom: 0;
  z-index: 1;
  max-width: 500px;
  margin-bottom: 50px;
  padding-right: 30px;

  .btn{
    margin-top:40px;
  }
}

.hero-title{
  font-size: rem(50);
  color: #ffffff;
  text-transform: uppercase;

  @include media-breakpoint-down(xl) {
    font-size: rem(50*0.85);
  }

  @include media-breakpoint-down(sm) {
    font-size: rem(50*0.75);
  }

  @include media-breakpoint-down(xs) {
    font-size: rem(50*0.65);
  }
}

.hero-desc{
  color: #ffffff;
  margin-top: 20px;
}

.hero.hero-alt{
  max-height: 350px;

  @include media-breakpoint-down(lg) {
    height: 240px !important;
  }
}

.hero.hero-alt .hero-image{

  @include media-breakpoint-down(sm) {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: initial;
    width: initial;
  }
}