.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 20px;
}

.breadcrumb-item {
  @extend .lato700;
  font-size: rem(14);
  line-height: 2;
  color: #646464;
  text-transform: uppercase;
}

.breadcrumb-item:after {
  display: inline-block;
  padding: 0 5px;
  color: $color-secondary;
  content: "/";
}

.breadcrumb-item:last-child:after {
  display: none;
}

.breadcrumb-item a {
  color: $color-secondary;
  text-decoration: none;
}
