// =============================================================================
// Border radius
// =============================================================================

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

// =============================================================================
// Rotate
// =============================================================================

@mixin rotate($deg){
  -ms-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  transform: rotate($deg);
}


// =============================================================================
// Hover transition
// =============================================================================

@mixin hover-transition(){
  -webkit-transition: linear .25s;
  -moz-transition:    linear .25s;
  -o-transition:     	linear .25s;
  -ms-transition:     linear .25s;
  transition:         linear .25s;
}

// =============================================================================
// Scale
// =============================================================================

@mixin scale($size){
  -webkit-transform: $size;
  -moz-transform:    $size;
  -ms-transform:     $size;
  -o-transform:      $size;
  transform:         $size;
}

// =============================================================================
// Px to rm
// =============================================================================

$browser-context: 16;

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem
}

// =============================================================================
// Unit less line height
// =============================================================================

@function line-height($font-size, $line-height: $font-size) {
  @return $line-height/$font-size;
}

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// =============================================================================
// Breakpoint viewport sizes and media queries.
// =============================================================================


@mixin media-breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  @else {
    @media (min-width: #{$breakpoint}) {
      @content;
    }
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint) - 0.02}) {
      @content;
    }
  }

  @else {
    @media (max-width: #{$breakpoint - 0.02}) {
      @content;
    }
  }
}

@mixin media-breakpoint-between($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint)  {
    @media (max-width: #{map-get($breakpoints, $breakpoint) - 0.02}) {
      @content;
    }
  }

  @else {
    @media (max-width: #{$breakpoint - 0.02}) {
      @content;
    }
  }
}

@mixin media-breakpoint-between($lower, $upper) {

  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 0.02)) {
      @content;
    }

  } @else {

    @media (min-width: $lower) and (max-width: ($upper - 0.02)) {
      @content;
    }
  }
}