.mask:after {
  content: "";
  background-image: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.6;
  width: 100%;
  height: 60%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;

  @include media-breakpoint-down(sm) {
    height: 100%;
  }
}