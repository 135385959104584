.cards > div {
  margin-bottom: 50px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:hover .card-img img {
    transform: scale3d(1.1, 1.1, 1);
  }

  .product-price {
    margin: 10px 0 0;
    .prev-price,
    .cur-price {
      @extend .lato400;
      display: block;
    }

    .prev-price {
      font-size: rem(14);
      margin-bottom: 0;
    }

    .cur-price {
      font-size: rem(16);
    }
  }
}

.card-header {
  position: relative;
}

.card-body {
  padding-top: 20px;
}

.card-title {
  color: #7d7d7d;
}

.card-img {
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  height: 260px;

  img {
    display: block;
    object-fit: contain;
    height: 100%;
    transform: scale3d(1, 1, 1);
    transition: transform 0.25s ease-in-out;
    will-change: transform;
    backface-visibility: hidden;
  }
}

.card .more-colors {
  display: inline-flex;
  height: 15px;
  width: 100%;
  margin-top: 15px;
  @extend .lato400;
  font-size: 12px;
  line-height: 15px;
  color: $color-primary;
  text-transform: uppercase;

  img {
    width: 15px;
    margin-right: 8px;
  }
}

.card .tag {
  position: absolute;
  bottom: 20px;
  right: -13px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 40px;
  background-color: $color-red;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0 0 20px;
  @extend .lato700;
  font-size: rem(16);
  line-height: 1;
  color: #ffffff;
  text-transform: uppercase;

  &:after {
    content: "";
    background: url("../img/tag.png");
    display: block;
    height: 8px;
    width: 13px;
    position: absolute;
    right: 0;
    bottom: -8px;
  }
}

.bg-primary .card-alt,
.bg-secondary .card-alt {
  .card-img {
    border-color: #fff;
  }
  .card-body {
    border-color: #fff;
  }
  .number {
    color: #fff;
  }
}
