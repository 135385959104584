// mobile navbar

.mobile-navbar {
  position: fixed;
  top: 0;
  z-index: $z-index-sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1340px;
  width: 100%;
  margin: auto;
  padding: 0 30px;
  height: 80px;
  border-bottom: 2px solid #61aabf;

  @include media-breakpoint-down(sm) {
    padding: 0 15px;
  }
}

.mobile-navbar .navbar-brand {
  width: 120px;
}

.mobile-navbar .cart-wrapper {
  margin-right: 10px;
}

.mobile-navbar .icon {
  width: 28px;
  height: 28px;
  margin-left: 10px;
}

.mobile-navbar .search-wrapper {
  top: 10px;
  right: 70px;
  width: auto;
  padding-left: 30px;

  @include media-breakpoint-down(sm) {
    right: 55px;
    padding-left: 15px;
  }

  .icon {
    margin-left: 0;
  }
}

// mobile menu

.mobile-menu {
  position: fixed;
  top: 80px;
  z-index: $z-index-fixed;
  min-height: calc(100vh - 80px);
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  background: #ffffff;
  padding: 30px;
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), visibility 0s 0.5s;
}

body.mobile-menu-opened {
  overflow-y: hidden;

  .mobile-menu {
    visibility: visible;
    transform: translateZ(0);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
