.accordion {
}

// accordion header

.accordion-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 38px 25px 0;
  @extend .lato700;
  font-size: rem(20);
  line-height: 1.25;
  color: $color-primary;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  outline: none;
  user-select: none;
  transition: 0.4s;
}

.accordion-header .icon-arrow {
  right: 5px;
  width: 20px;
  height: 20px;
  fill: #d9d9d9;
  position: absolute;
}

.accordion-item.active > .accordion-header,
.accordion-header:hover {
  color: $color-secondary !important;
}

.accordion-item.active > .accordion-header > .icon-arrow {
  transform: rotate(90deg);
}

// accordion panel

.accordion-panel {
  overflow: hidden;
  display: none;
}

.accordion-panel-list {
  padding-bottom: 15px;
}

.accordion-panel-item {
  display: block;
  padding: 20px;
  @extend .lato700;
  font-size: rem(18);
  line-height: 1.25;
  color: #646464;
  text-align: left;

  text-decoration: none;
  user-select: none;

  &:focus {
    outline: none;
  }
}

.accordion-panel-item.active,
.accordion-panel-item:hover {
  color: $color-secondary;
}

// accordion item

.accordion-item.accordion-panel-item {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.accordion-item.accordion-panel-item > .accordion-header {
  padding-top: 25px;
  padding-bottom: 25px;
}
