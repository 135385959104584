::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a5a6a8;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #a5a6a8;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #a5a6a8;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #a5a6a8;
}

.form-label,
label {
  @extend .lato400;
  font-size: rem(15);
  line-height: line-height(15, 20);
  color: $color-primary;
  display: inline-block;
  white-space: nowrap;
}

.form-label {
  margin-bottom: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;

  .error {
    position: absolute;
    padding-top: 7px;
    color: red !important;
    font-size: 12px;
  }
}

.form-group.inline {
  display: flex;
  align-items: center;

  label,
  .form-label {
    margin-right: 10px;
  }
}

.form-control {
  width: 100%;
  height: 40px;
  border: 0;
  background-color: #f2f3f4;
  @extend .lato400;
  color: #646464;
  font-size: rem(15);
  line-height: line-height(15, 36);
  padding: 0 20px;
  border-radius: 3px;

  &:focus {
    background: #c7f2ff;
    outline: none;
  }
}

textarea.form-control {
  height: auto;
}

textarea {
  resize: none;
}

.form-control.has-error {
  border: 1px solid red;
}

.success-message,
.error-message {
  margin-top: 10px;
  font-size: rem(16);
}

.success-message {
  color: green !important;
}

.error-message {
  color: red !important;
}

.subscribe {
  display: inline-flex;
  width: 100%;

  @include media-breakpoint-down(xs) {
    display: block;
  }

  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @include media-breakpoint-down(xs) {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .btn {
    min-width: 144px;
    padding: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    @include media-breakpoint-down(xs) {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      margin-top: 10px;
    }
  }
}
