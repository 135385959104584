// header

header {
  border-bottom: 2px solid $color-secondary;
}

.scroll {
  margin-top: 100px;

  .sticky-on-scroll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-index-sticky;
    background: white;
    border-bottom: 2px solid $color-secondary;
  }
}

// navbar belt

.navbar-belt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.navbar-belt ul {
  display: inline-flex;
}

.navbar-belt ul > li {
  margin-right: 50px;

  @include media-breakpoint-down(lg) {
    margin-right: 30px;
  }
}

.navbar-belt ul > li > a {
  @extend .lato400;
  font-size: rem(14);
}

// navbar-main

.navbar-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.navbar-main ul {
  display: inline-flex;
}

.navbar-main ul > li {
  padding: 40px;

  @include media-breakpoint-down(lg) {
    padding: 20px;
  }
}

.navbar-main ul > li > a {
  @extend .lato700;
  color: $color-primary;
  font-size: rem(18);
  line-height: 1.35;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;

  &.active-link,
  &:hover {
    color: $color-secondary;
  }
}

// navbar-brand

.navbar-brand {
  display: block;
  width: 160px;
  height: auto;
  margin: 15px 0;
}

// search

.search-show .icon,
.search-hide .icon {
  width: 28px;
  height: 28px;
  margin-left: 40px;

  &:hover {
    fill: $color-secondary;
  }

  @include media-breakpoint-down(lg) {
    margin-left: 20px;
  }
}

.search-wrapper {
  position: absolute;
  height: 60px;
  left: 0;
  right: 0;
  align-items: center;
  opacity: 0;
  display: flex;
  visibility: hidden;

  input {
    font-size: rem(22);
    height: 60px;

    @include media-breakpoint-down(md) {
      font-size: rem(16);
    }
  }

  .search-hide {
    margin-left: 10px;
  }
}

.search-wrapper.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease;
}

// dropdown

.navbar-main-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  z-index: $z-index-dropdown;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #ffffff;
  padding: 0 30px 30px;
  border-top: 2px solid #61aabf;
  border-bottom: 2px solid $color-primary;
  visibility: hidden;
  opacity: 0;

  @include media-breakpoint-down(lg) {
    margin-top: 20px;
    padding: 0 20px 20px;
  }
}

.navbar-main-dropdown.show {
  visibility: visible;
  opacity: 1;
  padding-top: 30px;
  transition: 0.4s ease;

  @include media-breakpoint-down(lg) {
    padding-top: 20px;
  }
}

.navbar-main-dropdown a {
  display: block;
  margin: 10px 0;
  padding: 0 20px;
  border-right: 2px solid #e6e6e6;
  text-align: center;
  width: 20%;
  max-width: 200px;
  cursor: pointer;

  @include media-breakpoint-down(lg) {
    padding: 0 20px;
  }

  img {
    max-width: 80px;
    margin: auto;

    @include media-breakpoint-down(lg) {
      max-width: 60px;
    }
  }

  &:last-child {
    border: 0;
  }

  &:hover h4 {
    color: $color-secondary;
  }
}

.navbar-main-dropdown h4 {
  @extend .lato700;
  font-size: rem(16);
  line-height: 1.2;
  margin-top: 20px;
  transition: color 0.15s ease-in-out;
}

a.sale {
  color: #e31b23 !important;
}
