body {
  @include media-breakpoint-down(md) {
    margin-top: 80px;
  }
}

.container {
  max-width: 1340px;
  width: 100%;
  margin: auto;
  padding: 0 30px;

  @include media-breakpoint-down(sm) {
    padding: 0 15px;
  }
}

.bg-f7f7f7 {
  background-color: #f7f7f7;
}

.bg-ffffff {
  background-color: #ffffff;
}

.bg-primary {
  background-color: $color-primary;
}

.bg-secondary {
  background-color: $color-secondary;
}

.img-fluid {
  display: block;
  width: 100%;
  height: auto;
}

.mt-auto {
  margin-top: auto !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-75 {
  margin-right: 75px !important;
}
.pt-0 {
  padding-top: 0 !important;
}

.d-flex {
  display: flex !important;
}

.flex-1 {
  flex: 1 !important;
}
.align-center {
  align-items: center !important;
}

.flex-xs-direction {
  @include media-breakpoint-down(xs) {
    flex-direction: column !important;
  }
}

.hide-md {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.show-md {
  display: none !important;

  @include media-breakpoint-down(md) {
    display: block !important;
  }
}

// other stuff

.social-media {
  display: flex;
  margin-top: 20px;

  .icon {
    margin-right: 20px;
    width: 25px;
    height: 25px;
    fill: #ffffff;
    transition: color 0.15s ease-in-out;

    &:hover {
      fill: $color-primary;
    }
  }
}

.load-more {
  text-align: center;
  margin-top: 80px;
}

.text-center {
  text-align: center !important;
}

i,
em {
  font-style: italic;
}
