.article {
  h2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  h3 {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p {
    margin-top: 10px;
    margin-bottom: 20px;
    line-height: 1.8;
    color: $color-text;
  }
  a {
    display: inline-block;
    // margin-bottom: 20px;
    font-size: rem(18);
  }

  img {
    display: block;
    width: calc(100% + 60px);
    height: auto;
    margin: 30px -30px;

    @include media-breakpoint-down(sm) {
      width: auto;
      height: 250px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ol,
  ul {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    list-style-type: disc;
  }

  li {
    @extend .lato400;
    font-size: rem(18);
    line-height: 1.8;
    color: $color-text;

    @include media-breakpoint-down(xl) {
      font-size: rem(18 * 0.85);
    }
  }

  p + ol,
  p + ul {
    margin-top: -10px;
  }

  table {
    .li {
      font-size: rem(16);
      line-height: line-height(15, 24);
    }
    a {
      font-size: rem(16);
    }
  }

  i,
  em {
    font-style: italic;
  }

  b,
  strong {
    font-weight: 700;
  }

  &.technical-details {
    img {
      display: inline-block;
      margin: auto;
    }
  }
}
