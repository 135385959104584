/*=========|  RESETS  |=========*/
/*===============================*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

html {
  cursor: default;
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
  &:before, &:after {
    content: '';
    content: none;
  }
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*{
  box-sizing: border-box;
}

a, a:active, a:hover {
  outline: none
}

abbr {
  _border-bottom: expression(this.title ? '1px dotted':'none')
}

abbr[title] {
  border-bottom: 1px dotted
}

b, strong {
  font-weight: bold
}

dfn {
  font-style: italic
}

mark {
  background: #FF0;
  color: #000
}

pre, code, kbd, samp {
  font-family: monospace, monospace;
  _font-family: 'courier new', monospace;
  font-size: 1em
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word
}

q {
  quotes: none
}

q:before, q:after {
  content: '';
  content: none
}

small, sub, sup {
  font-size: 75%
}

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -0.5em
}

sub {
  bottom: -0.25em
}

nav ul {
  list-style: none
}

audio[controls], canvas, video {
  display: inline-block;
  *display: inline
}

audio {
  display: none;
  _display: expression(this.controls ? 'inline':'none');
  *zoom: 1
}

audio[controls] {
  display: inline-block
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic
}

svg:not(:root) {
  overflow: hidden
}

legend {
  *margin-left: -7px
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  -webkit-appearance: none;
  border-radius: 0;
  vertical-align: baseline;
  *vertical-align: middle
}

button, input {
  line-height: normal;
  _overflow: expression(this.type == 'button|reset|submit' ? 'visible':'')
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  overflow: visible
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto;
  vertical-align: top
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
