.pdf-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pdf-thumbnail {
  background: #fff;
  display: flex;
  align-items: center;
  height: 100%;
}

.pdf-canvas {
  width: 100%;
}

.pdf-title {
  margin-top: auto;
  padding-top: 15px;
}
