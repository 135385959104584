/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  user-select: none;
  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: 1;
    }
  }
  &.slick-disabled:before {
    opacity: 0.25;
  }
  &:before {
    font-size: 20px;
    line-height: 1;
    color: #00172b;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -25px;
  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }
  &:before {
    content: "\2190";
    [dir="rtl"] & {
      content: "\2192";
    }
  }
}

.slick-next {
  right: -25px;
  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }
  &:before {
    content: "\2192";
    [dir="rtl"] & {
      content: "\2190";
    }
  }
}

/* Custom styling */

.slick-slider {
  position: relative;
}

.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  height: auto;
}

.slider-control {
  // margin-top: 30px;

  .prev,
  .next {
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    user-select: none;

    .icon {
      height: 15px;
      width: 15px;
      fill: #ffffff;
    }

    &:hover .icon {
      fill: $color-secondary;
    }
  }

  .prev {
    margin-right: 5px;
  }

  .next {
    margin-left: 5px;
  }

  span {
    @extend .lato400;
    font-size: rem(12);
    color: #ffffff;
  }
}

.main-slider {
  .slider-control {
    margin-top: 50px;
  }
}

.products-slider {
  margin: 0 rem(-15);

  .slick-list {
    margin-top: -15px 0;
    padding: 15px 0;
  }

  .slick-slide {
    margin: 0 rem(15);
  }

  .slider-control {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    .icon {
      fill: $color-primary;
    }
  }
}

.product-gallery-slider {
  position: relative;
  padding: 0 40px;

  .slick-slide {
    padding: 20px;
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;

    &:hover .icon {
      fill: $color-secondary;
    }
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
}
