h1,
.h1 {
}

h2,
.h2 {
  @extend .lato700;
  color: $color-secondary;
  font-size: rem(38);
  line-height: 1.2;

  @include media-breakpoint-down(xl) {
    font-size: rem(38 * 0.85);
  }

  @include media-breakpoint-down(sm) {
    font-size: rem(38 * 0.75);
  }
}

h3,
.h3 {
  @extend .lato700;
  color: $color-primary;
  font-size: rem(28);
  line-height: 1.2;

  @include media-breakpoint-down(xl) {
    font-size: rem(28 * 0.85);
  }

  @include media-breakpoint-down(sm) {
    font-size: rem(28 * 0.75);
  }
}

h4,
.h4 {
  @extend .lato400;
  color: $color-primary;
  font-size: rem(18);
  line-height: 1.2;

  @include media-breakpoint-down(xl) {
    font-size: rem(18);
  }

  @include media-breakpoint-down(sm) {
    font-size: rem(18);
  }
}

p,
.p {
  @extend .lato400;
  color: $color-primary;
  font-size: rem(18);
  line-height: 1.4;
}

a {
  @extend .lato700;
  text-decoration: none;
  color: $color-primary;
  font-size: rem(14);
  line-height: line-height(16, 24);
  transition: color 0.15s ease-in-out;

  &:hover {
    color: $color-secondary;
  }
}
