// include fonts

@include font-face(Lato-Regular, "../fonts/Lato/Lato-Regular/Lato-Regular", 400, normal);
@include font-face(Lato-Bold, "../fonts/Lato/Lato-Bold/Lato-Bold", 700, normal);

// fonts

.lato400{
  font-family: 'Lato-Regular', Helvetica Neue, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato700{
  font-family: 'Lato-Bold', Helvetica Neue, sans-serif;
  font-weight: 700;
  font-style: normal;
}