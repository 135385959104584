.ui.icon.message {
  height: auto;
}

.ui.button {
  height: auto;
  width: auto;
}

// checkout table override

.ui.table td {
  vertical-align: middle;

  button {
    margin-right: 0 !important;
  }

  &.remove-item {
    width: 1%;
    white-space: nowrap;
  }

  @include media-breakpoint-down(sm) {
    white-space: normal !important;
  }
}

.ui.table:not(.unstackable) thead {
  display: none !important;
}

.ui.header{
  margin-bottom: 5px;
}

.ui.header > img {
  margin: 10px 10px 10px 0;
}

.ui.header > img + .content {
  padding-left: 0;
  vertical-align: middle;
}

.ui.header i.circular.icon {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
