.section {
  padding: 60px 0;

  @include media-breakpoint-down(md) {
    padding: 40px 0;
  }
}

.section-header {
  margin-bottom: 60px;

  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}

.section-title {
  text-align: center;
}

.section-subtitle {
  text-align: center;
}

.section-desc {
  // max-width: 660px;
  width: 100%;
  margin: 25px auto 0;
  text-align: center;
}
