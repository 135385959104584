.btn {
  display: inline-block;
  min-width: 120px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 12px 30px;
  @extend .lato400;
  font-size: rem(14);
  line-height: 1;
  color: $color-primary;
  text-transform: uppercase;
  //white-space: nowrap;
  cursor: pointer;
  outline: none;
  box-shadow: 0 3px 15px rgba(15, 135, 141, 0.2),
    0 1px 3px rgba(15, 135, 141, 0.1);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;

  &:disabled {
    background-color: #b4b4b4 !important;
    color: #808080 !important;
    border-color: #808080 !important;
    box-shadow: none !important;
    cursor: default !important;
  }
}

.btn-primary {
  color: #ffffff;
  background-color: $color-primary;
  border-color: $color-primary;

  &:hover {
    color: #ffffff;
    background-color: $color-secondary;
    border-color: $color-secondary;
  }
}

.btn-secondary {
  color: #ffffff;
  background-color: $color-secondary;
  border-color: $color-secondary;

  &:hover {
    color: #ffffff;
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.btn-ghost {
  color: $color-secondary;
  background-color: transparent;
  border-color: $color-secondary;

  &:hover {
    color: #ffffff;
    background-color: $color-secondary;
    border-color: $color-secondary;
  }
}
