.filters{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;

  .results{
    margin-bottom: 20px;
    margin-right: 50px;
    font-size: rem(15);
  }

  .form-group{
    margin-right: 20px;

    &:last-child{
      margin-right: 0;
    }
  }
}

.filters .inline{
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  &:first-child{
    @include media-breakpoint-down(xs) {
      margin-top: 20px;
      margin-right: 0;
    }
  }

  label, .form-label{
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }
}