.contact-cards > div{
  margin-bottom: 30px;
}

.contact-card {
  height: 100%;
  background-position: bottom right;
  background-size: 280px;
  background-repeat: no-repeat;
  padding: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .contact-card-content {
    h3, p {
      color: #f7f7f7;
    }

    h3 {
      margin-bottom: 15px;
    }
  }
}